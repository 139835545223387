import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'

export default () => (
    <Layout>
        <NotFound>404</NotFound>
    </Layout>
)

const NotFound = styled.p`
    font-size: 3rem;
    justify-self: center;
    align-self: center;
`